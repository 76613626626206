<template lang="pug">
include ../../../configs/template
div.col-12
  div.row
    h2.col-12.text-left Оригінал:
  div.row
    div.col-sm-12(v-if="documentInfo.created_at")
      +data-info('createDate','getDateFormat(documentInfo.created_at)')
    div.col-sm-12(v-if="documentInfo.number_document")
      +data-info('docNumber','documentInfo.number_document')
    div.col-sm-12(v-if="documentInfo.serial")
      +data-info('serial','documentInfo.serial')
    div.col-sm-12(v-if="documentInfo.registry_number")
      +data-info('registrationNumber','documentInfo.registry_number')
    div(v-if="documentInfo.extent_original").col-sm-12
      +data-info('original.extent_original','documentInfo.extent_original')
    div(v-if="fullNameOriginal").col-sm-12
      +data-info('original.fullNameOriginal','fullNameOriginal')
    div(v-if="documentInfo.name_nz_original").col-sm-12
      +data-info('original.name_nz_original','documentInfo.name_nz_original')
    div(v-if="documentInfo.qualification_original").col-sm-12
      +data-info('original.qualification_original','documentInfo.qualification_original')
    div(v-if="documentInfo.specialization_original").col-sm-12
      +data-info('original.specialization_original','documentInfo.specialization_original')
    div(v-if="documentInfo.speciality_origin").col-sm-12
      +data-info('original.speciality_origin','documentInfo.speciality_origin')
    div(v-if="documentInfo.special_notes_original").col-sm-12
      +data-info('original.special_notes_original','documentInfo.special_notes_original')
    div.col-sm-12(v-if="documentInfo.date_start_educ")
      +data-info('date_start_educ','getDateFormat(documentInfo.date_start_educ)')
    div.col-sm-12(v-if="documentInfo.date_issue_document")
      +data-info('dateIssue','getDateFormat(documentInfo.date_issue_document)')
    div.col-sm-12(v-if="documentInfo.date_end_educ")
      +data-info('date_end_educ','getDateFormat(documentInfo.date_end_educ)')
</template>
<script>
import { getDateFormat } from '@/mixins/main'
import { mapGetters } from 'vuex'
export default {
  props: { documentInfo: { type: Object, default: () => ({}) } },
  data () {
    return {
      getDateFormat

    }
  },
  computed: {
    fullNameOriginal () {
      return `${this.documentInfo?.last_name_original || ''} ${this.documentInfo?.first_name_original || ''} ${
        this.documentInfo?.middle_name_original || ''
      }`
    },
    ...mapGetters(['getDirectoryObject'])
  }
}
</script>
